import LeaderboardEntry from "./LeaderboardEntry";

function Leaderboard() {
  const leaderboard: leaderboardEntry[] = [
    { id: 1, name: "Merijn", score: 2587 },
    { id: 2, name: "Freek", score: 3950 },
    { id: 3, name: "Pol", score: 9999 },
    { id: 4, name: "Sjors", score: 4638 },
    { id: 5, name: "Sjoerd", score: 4265 },
    { id: 6, name: "Spijker", score: 3741 },
  ];

  leaderboard.sort((a, b) => a.score - b.score);

  console.log(leaderboard[0].id);

  return (
    <div className="flex h-screen">
      <div className="mx-auto my-5">
        <div className="p-4 max-w-md bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Leaderboard</h3>
            <a href="/#" className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
              View all
            </a>
          </div>

          <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {leaderboard.map((entry, index) => (
                <LeaderboardEntry
                  {...entry}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

interface leaderboardEntry {
  id: number;
  name: string;
  score: number;
}

export default Leaderboard;