function LeaderboardEntry(entry: leaderboardEntry) {
  return (
    <li className="py-3 sm:py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <img className="w-8 h-8 rounded-full" src={`https://randomuser.me/api/portraits/men/${entry.id}.jpg`} alt={entry.name} />
        </div>
        <div className="flex-1 min-w-0 pr-36">
          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
            {entry.name}
          </p>
          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
            poep@poep.nl
          </p>
        </div>
        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
          {entry.score} ms
        </div>
      </div>
    </li>
  );
};

interface leaderboardEntry {
  id: number;
  name: string;
  score: number;
}

export default LeaderboardEntry;